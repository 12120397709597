<template>
  <div>
    <v-sheet class="header-backup"></v-sheet>
    <v-app-bar
      :color="$vuetify.theme.dark ? 'dark' : getThemeMode.appBarColor"
      :dark="getThemeMode.appBarColor != 'white' ? true : false"
      app
      class="px-sm text-left shadow-sm ma-4 rounded-lg"
      flat
      height="75"
    >
      <v-app-bar-nav-icon
        v-ripple="{ class: 'primary--text' }"
        @click="toggleVerticalSidebarDrawer"
        alt="navigation_hamburger"
      />
      <!-- <vue-navigation-bar class="mt-0" :options="navbarOptions" /> -->
      <v-progress-linear
        id="loading_indicator"
        :active="getThemeMode.isLoading"
        :indeterminate="getThemeMode.isLoading"
        absolute
        bottom
        color="primary"
      />

      <div class="mr-3">
        <v-img
          v-if="getThemeMode.verticalSidebarDrawerColor == 'dark'"
          contain
          src="/assets/logo/ktg_green.svg"
        />
        <v-img v-else src="/assets/logo/ktg_green.svg" contain max-height="30" width="30"/>
      </div>

      <h5 class="mb-0">{{routename}}</h5>

      <v-spacer />
      <div v-if="this.$vuetify.breakpoint.smAndUp" class="mr-4"><h4>Know the Game</h4></div>

      <v-badge
        bordered
        overlap
        content="6"
        :color="secondaryColour"
        offset-x="22"
        offset-y="22"
        v-if="loggedInUser && false"
      >
        <v-btn icon @click="notificationDrawer = !notificationDrawer">
          <v-icon>mdi-bell</v-icon>
        </v-btn>
      </v-badge>

      <v-chip pill class="transparent py-5" @click="userDrawer = !userDrawer" v-if="loggedInUser">
        <v-icon>mdi-account-circle-outline</v-icon>
      </v-chip>
      <template v-slot:extension v-if="message">
        <div
          class="maintenance-extension py-2"
          :style="messageStyle"
        >
          {{ $vuetify.breakpoint.smAndDown ? smallMessage : message }}
        </div>
      </template>
    </v-app-bar>
    <!-- userDrawer -->
    <v-navigation-drawer
      v-model="userDrawer"
      fixed
      right
      height="100%"
      temporary
      floating
      width="350"
    >
      <user-drawer>
        <template v-slot:userDrawerCloseButton>
          <v-btn icon color @click.stop="userDrawer = !userDrawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </user-drawer>

      <template v-slot:append>
        <div class="my-4 mx-4">
          <base-hover-button
            @click.native="logoutUser"
            text="Logout"
            block
            bg-color="primary lighten-5 primary--text"
            icon-name="mdi-logout"
          />
        </div>
      </template>
    </v-navigation-drawer>

    <!-- notificationDrawer  -->
    <v-navigation-drawer
      v-model="notificationDrawer"
      fixed
      right
      height="100%"
      temporary
      floating
      width="350"
    >
      <notification-drawer>
        <template v-slot:notificationDrawerCloseButton>
          <v-btn
            icon
            color
            @click.stop="notificationDrawer = !notificationDrawer"
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </notification-drawer>

      <template v-slot:append>
        <div class="my-4 mx-4">
          <base-hover-button
            text="View All Notifications"
            block
            bg-color="primary lighten-5 primary--text"
          />
        </div>
      </template>
    </v-navigation-drawer>
    <!-- searchDrawer -->
    <v-navigation-drawer
      v-model="searchDrawer"
      fixed
      right
      height="100%"
      temporary
      floating
      width="380"
    >
      <search-drawer>
        <template v-slot:searchDrawerCloseButton>
          <v-btn icon color @click.stop="searchDrawer = !searchDrawer">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </template>
      </search-drawer>
    </v-navigation-drawer>
  </div>
</template>

<script>
import Vuetify from "vuetify/lib";
import { mapGetters, mapActions } from "vuex";

export default {
  name: "VerticallAppBar",
  components: {
    UserDrawer: () => import("../common-drawer/UserDrawer.vue"),
    NotificationDrawer: () => import("../common-drawer/NotificationDrawer.vue"),
    SearchDrawer: () => import("../common-drawer/SearchDrawer.vue")
  },
  computed: {
    ...mapGetters(["getThemeMode", "loggedInUser"]),
    secondaryColour(){
      return this.$vuetify.theme.isDark ? `${this.$vuetify.theme.themes.dark.secondary}` : `${this.$vuetify.theme.themes.light.secondary}`;
    },
    routename() {
      // Return the current route name as a computed property
      return this.$route.meta.label;
    },
    messageStyle(){
      return {
        backgroundColor: this.messageBgColor,
        color: this.messageTextColor,
        fontWeight: "bold",
        textAlign: "center",
        padding: "10px",
        fontSize: this.$vuetify.breakpoint.smAndDown ? "0.8rem" : "1.2rem",
      };
    }
  },
  data() {
    return {
      userDrawer: false,
      notificationDrawer: false,
      searchDrawer: false,
      message: '',
      smallMessage: '',
      messageBgColor: '',
      messageTextColor: '',
      navbarOptions: {
        elementId: "main-navbar",
        isUsingVueRouter: true,
        mobileBreakpoint: 992,
        brandImagePath: "./",
        brandImageAltText: "brand-image",
        collapseButtonOpenColor: "#661c23",
        collapseButtonCloseColor: "#661c23",
        showBrandImageInMobilePopup: true,
        ariaLabelMainNav: "Main Navigation",
        tooltipAnimationType: "shift-away"
      }
    };
  },
  methods: {
    ...mapActions([
      "changeVerticalSidebarDrawer",
      "changeVerticalSidebarMini",
      "signOut"
    ]),
    toggleVerticalSidebarDrawer() {
      this.changeVerticalSidebarDrawer();

      // this.$emit("update:mini-variant");
      //console.log(this.$vuetify.theme.primary);
    },
    logoutUser() {
      this.signOut();

      this.$router.push("/app/sessions/sign-in");
    },
    async fetchMaintenanceData() {
      try {
        const response = await fetch("/status.json");
        const data = await response.json();
        this.message = data.message;
        this.smallMessage = data.smallMessage;
        this.messageBgColor = data.messageBgColor;
        this.messageTextColor = data.messageTextColor;
        //this.maintenance = data;
      } catch (error) {
        console.error("Failed to fetch maintenance data:", error);
      }
    },
  },
  mounted() {
    this.fetchMaintenanceData();
  },

};
</script>

<style lang="scss">
.maintenance-extension {
  width: 100%;
}
.header-backup {
  display: block;
  width: 100%;
  height: 102px;
  position: fixed;
  top: 0;
  z-index: 5;
  background: linear-gradient(
    180deg,
    hsla(0, 0%, 97.3%, 0.95) 44%,
    hsla(0, 0%, 97.3%, 0.46) 73%,
    hsla(0, 0%, 100%, 0)
  ) !important;
  left: 0;
  &.theme--dark {
    background: linear-gradient(
      180deg,
      rgba(5, 5, 5, 0.95) 44%,
      rgba(0, 0, 0, 0.46) 3%,
      hsla(0, 0%, 100%, 0)
    ) !important;
  }
}
.tippy-box[data-theme~="light"] {
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
}
.vnb {
  background: transparent !important;
  &__menu-options {
    margin-top: 3px !important;
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        &:hover {
          color: #0081ff;
          .vnb__menu-options__option__arrow {
            fill: #0081ff;
          }
        }

        &__icon {
          svg {
            fill: #0081ff !important;
          }
        }
      }
    }
  }

  &__sub-menu-options {
    &__option {
      &__link {
        &:focus {
          outline: none;
          border: 1px solid none;
        }
        color: #000 !important;
        &:hover {
          color: #0081ff !important;
        }
      }
    }
  }
}

.vnb__collapse-button {
  &:focus {
    border: 1px solid none;
    outline: none;
  }
  &:after {
    content: "\F035C";
    font-size: 25px;
    font-weight: 600;
    font-family: "Material Design Icons";
  }
  svg {
    display: none !important;
  }
}

.vnb__popup {
  max-height: 80vh;
  overflow-x: hidden;
  overflow-y: scroll !important;
  .vnb__popup__top__close-button {
    &:focus {
      border: 1px solid none;
      outline: none;
    }
    top: 20px;
    right: -33px;
    svg {
      fill: #000 !important;
    }
  }
}
</style>
